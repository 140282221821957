.scw-header {
  margin-bottom: 16px;
}

h1.scw-header,
h2.scw-header,
h3.scw-header,
h4.scw-header,
h5.scw-header,
h6.scw-header {
  font-family: "Open Sans", sans-serif !important;
}

h1.scw-header {
  font-size: 32px !important;
  font-weight: 600 !important;
}

h2.scw-header {
  font-size: 28px !important;
  font-weight: 600 !important;
}

h3.scw-header {
  font-size: 24px !important;
  font-weight: 600 !important;
}

h4.scw-header {
  font-size: 20px !important;
  font-weight: 600 !important;
}

h5.scw-header {
  font-size: 18px !important;
  font-weight: 600 !important;
}

h6.scw-header {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.scw-fs-32px {
  --scw-fs: 32px;
}

.scw-fs-28px {
  --scw-fs: 28px;
}

.scw-fs-24px {
  --scw-fs: 24px;
}

.scw-fs-20px {
  --scw-fs: 20px;
}

.scw-fs-18px {
  --scw-fs: 18px;
}

.scw-fs-16px {
  --scw-fs: 16px;
}

.scw-fs-14px {
  --scw-fs: 14px;
}

.scw-fs-10px {
  --scw-fs: 10px;
}

.scw-fw-800 {
  --scw-fw: 800;
}

.scw-fw-700 {
  --scw-fw: 700;
}

.scw-fw-600 {
  --scw-fw: 600;
}

.scw-fw-500 {
  --scw-fw: 500;
}

.scw-fw-400 {
  --scw-fw: 400;
}

.scw-modal-base .modal-dialog .modal-content, .modal.scw-modal-auto-size .modal-dialog .modal-content, .modal.scw-modal-xl .modal-dialog .modal-content, .modal.scw-modal-lg .modal-dialog .modal-content, .modal.scw-modal-md .modal-dialog .modal-content, .modal.scw-modal-sm .modal-dialog .modal-content {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: unset;
}
.scw-modal-base .modal-dialog .modal-content .modal-header.scw-modal-header, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-header.scw-modal-header, .modal.scw-modal-xl .modal-dialog .modal-content .modal-header.scw-modal-header, .modal.scw-modal-lg .modal-dialog .modal-content .modal-header.scw-modal-header, .modal.scw-modal-md .modal-dialog .modal-content .modal-header.scw-modal-header, .modal.scw-modal-sm .modal-dialog .modal-content .modal-header.scw-modal-header {
  height: 68px !important;
  background: #53585f !important;
  border-bottom: 4px solid #ef5323 !important;
  color: #fff !important;
  padding: 16px 24px !important;
}
.scw-modal-base .modal-dialog .modal-content .modal-header.scw-modal-header .close, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-header.scw-modal-header .close, .modal.scw-modal-xl .modal-dialog .modal-content .modal-header.scw-modal-header .close, .modal.scw-modal-lg .modal-dialog .modal-content .modal-header.scw-modal-header .close, .modal.scw-modal-md .modal-dialog .modal-content .modal-header.scw-modal-header .close, .modal.scw-modal-sm .modal-dialog .modal-content .modal-header.scw-modal-header .close {
  padding: 20px 20px !important;
  font-size: 17px !important;
  text-shadow: initial !important;
}
.scw-modal-base .modal-dialog .modal-content .modal-body .unordered-error-list, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-body .unordered-error-list, .modal.scw-modal-xl .modal-dialog .modal-content .modal-body .unordered-error-list, .modal.scw-modal-lg .modal-dialog .modal-content .modal-body .unordered-error-list, .modal.scw-modal-md .modal-dialog .modal-content .modal-body .unordered-error-list, .modal.scw-modal-sm .modal-dialog .modal-content .modal-body .unordered-error-list {
  font-weight: 700 !important;
  padding-left: 20px !important;
  list-style-type: disc !important;
}
.scw-modal-base .modal-dialog .modal-content .modal-body .unordered-error-list-margin, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-body .unordered-error-list-margin, .modal.scw-modal-xl .modal-dialog .modal-content .modal-body .unordered-error-list-margin, .modal.scw-modal-lg .modal-dialog .modal-content .modal-body .unordered-error-list-margin, .modal.scw-modal-md .modal-dialog .modal-content .modal-body .unordered-error-list-margin, .modal.scw-modal-sm .modal-dialog .modal-content .modal-body .unordered-error-list-margin {
  margin-bottom: 4px !important;
}
.scw-modal-base .modal-dialog .modal-content .modal-footer, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-footer, .modal.scw-modal-xl .modal-dialog .modal-content .modal-footer, .modal.scw-modal-lg .modal-dialog .modal-content .modal-footer, .modal.scw-modal-md .modal-dialog .modal-content .modal-footer, .modal.scw-modal-sm .modal-dialog .modal-content .modal-footer {
  overflow: unset;
}
.scw-modal-base .modal-dialog .modal-content .modal-footer .buttons-left, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-footer .buttons-left, .modal.scw-modal-xl .modal-dialog .modal-content .modal-footer .buttons-left, .modal.scw-modal-lg .modal-dialog .modal-content .modal-footer .buttons-left, .modal.scw-modal-md .modal-dialog .modal-content .modal-footer .buttons-left, .modal.scw-modal-sm .modal-dialog .modal-content .modal-footer .buttons-left {
  margin-right: auto;
}
.scw-modal-base .modal-dialog .modal-content .modal-footer .buttons-left scw-mat-button:not(:last-child) button, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-footer .buttons-left scw-mat-button:not(:last-child) button, .modal.scw-modal-xl .modal-dialog .modal-content .modal-footer .buttons-left scw-mat-button:not(:last-child) button, .modal.scw-modal-lg .modal-dialog .modal-content .modal-footer .buttons-left scw-mat-button:not(:last-child) button, .modal.scw-modal-md .modal-dialog .modal-content .modal-footer .buttons-left scw-mat-button:not(:last-child) button, .modal.scw-modal-sm .modal-dialog .modal-content .modal-footer .buttons-left scw-mat-button:not(:last-child) button,
.scw-modal-base .modal-dialog .modal-content .modal-footer .buttons-right scw-mat-button:not(:last-child) button,
.modal.scw-modal-auto-size .modal-dialog .modal-content .modal-footer .buttons-right scw-mat-button:not(:last-child) button,
.modal.scw-modal-xl .modal-dialog .modal-content .modal-footer .buttons-right scw-mat-button:not(:last-child) button,
.modal.scw-modal-lg .modal-dialog .modal-content .modal-footer .buttons-right scw-mat-button:not(:last-child) button,
.modal.scw-modal-md .modal-dialog .modal-content .modal-footer .buttons-right scw-mat-button:not(:last-child) button,
.modal.scw-modal-sm .modal-dialog .modal-content .modal-footer .buttons-right scw-mat-button:not(:last-child) button {
  margin-right: 10px;
}
.scw-modal-base .modal-dialog .modal-content .modal-section, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-section, .modal.scw-modal-xl .modal-dialog .modal-content .modal-section, .modal.scw-modal-lg .modal-dialog .modal-content .modal-section, .modal.scw-modal-md .modal-dialog .modal-content .modal-section, .modal.scw-modal-sm .modal-dialog .modal-content .modal-section {
  display: flex;
  flex-direction: row;
}
.scw-modal-base .modal-dialog .modal-content .modal-info-icon, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-info-icon, .modal.scw-modal-xl .modal-dialog .modal-content .modal-info-icon, .modal.scw-modal-lg .modal-dialog .modal-content .modal-info-icon, .modal.scw-modal-md .modal-dialog .modal-content .modal-info-icon, .modal.scw-modal-sm .modal-dialog .modal-content .modal-info-icon {
  font-size: 22px;
  color: #666666;
  padding-right: 7px;
}

.modal.scw-modal-sm:not(.scw-modal-all-scrollable) .modal-dialog .modal-content,
.modal .scw-modal-md:not(.scw-modal-all-scrollable) .modal-dialog .modal-content,
.modal .scw-modal-lg:not(.scw-modal-all-scrollable) .modal-dialog .modal-content,
.modal .scw-modal-xl:not(.scw-modal-all-scrollable) .modal-dialog .modal-content {
  max-height: calc(100vh - 3.8em);
}
.modal.scw-modal-sm:not(.scw-modal-all-scrollable) .modal-dialog .modal-content .modal-body,
.modal .scw-modal-md:not(.scw-modal-all-scrollable) .modal-dialog .modal-content .modal-body,
.modal .scw-modal-lg:not(.scw-modal-all-scrollable) .modal-dialog .modal-content .modal-body,
.modal .scw-modal-xl:not(.scw-modal-all-scrollable) .modal-dialog .modal-content .modal-body {
  overflow-y: auto;
}
.modal.scw-modal-sm .modal-dialog .modal-content {
  max-width: 472px !important;
  width: 100%;
}
@media (min-width: 576px) {
  .modal.scw-modal-md .modal-dialog {
    max-width: 720px !important;
  }
}
.modal.scw-modal-md .modal-dialog .modal-content {
  max-width: 720px !important;
  width: 100%;
}
.modal.scw-modal-lg {
  padding: 0 40px;
}
.modal.scw-modal-lg .modal-dialog {
  max-width: 832px;
}
@media (max-width: 800px) {
  .modal.scw-modal-lg .modal-dialog {
    max-width: 100% !important;
  }
}
.modal.scw-modal-xl {
  padding: 0 40px;
}
.modal.scw-modal-xl .modal-dialog {
  width: 100% !important;
  max-width: none !important;
}
.modal.scw-modal-xl .modal-dialog .modal-content {
  width: 100% !important;
}
.modal.scw-modal-auto-size {
  padding: 0 40px;
}
.modal.scw-modal-auto-size .modal-dialog {
  width: 100% !important;
  max-width: none !important;
}
.modal.scw-modal-auto-size .modal-dialog .modal-content {
  width: fit-content !important;
  margin: auto !important;
}
@media screen and (max-width: 1024px) {
  .modal.scw-modal-auto-size .modal-dialog .modal-content {
    min-width: 700px !important;
  }
}
@media screen and (min-width: 1025px) {
  .modal.scw-modal-auto-size .modal-dialog .modal-content {
    min-width: 1000px !important;
  }
}

.modal-main-padding, .scw-modal-base .modal-dialog .modal-content .modal-body, .scw-modal-base .modal-dialog .modal-content .modal-footer, .modal.scw-modal-sm .modal-dialog .modal-content .modal-body, .modal.scw-modal-sm .modal-dialog .modal-content .modal-footer, .modal.scw-modal-md .modal-dialog .modal-content .modal-body, .modal.scw-modal-md .modal-dialog .modal-content .modal-footer, .modal.scw-modal-lg .modal-dialog .modal-content .modal-body, .modal.scw-modal-lg .modal-dialog .modal-content .modal-footer, .modal.scw-modal-xl .modal-dialog .modal-content .modal-body, .modal.scw-modal-xl .modal-dialog .modal-content .modal-footer, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-body, .modal.scw-modal-auto-size .modal-dialog .modal-content .modal-footer {
  padding: 12px 24px !important;
}

.scw_mat-add_edit_modal-body {
  display: flex;
  flex-direction: column;
}
.scw_mat-add_edit_modal-item {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.scw_mat-add_edit_modal-item-checkbox {
  flex-basis: 24px;
  margin-right: 16px;
  margin-top: 24px;
  height: 40px;
}
.scw_mat-add_edit_modal-item-input {
  flex: 1;
}
.scw_mat-add_edit_modal-item .scw-mat-input-host {
  flex-grow: 1;
}
.scw_mat-add_edit_modal-item .scw-mat-input-host_with-button {
  margin-right: 15px;
}

hr.scw-hr {
  margin: 10px 0 20px 0;
  border-color: #53585f;
  border-style: solid;
}

app-datatable-row {
  display: contents;
}

scw-mat-form div.inner-form-section {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 1px solid #a8a8a8;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}